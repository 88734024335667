<template>
    <div></div>
</template>

<script>
    export default {
        name: "RedirectIndex"
    }
</script>

<style scoped>

</style>